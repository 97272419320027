@import '@vg-constellation/angular-15/styles/c11n';
//@import '@vg-constellation/styles/c11n';


body,
html {
  margin: 0;
  padding: 0;
  font-family: $c11n-font-family-sans;
}

a {
  text-decoration: none;
}


.vgn-skip-navigation,
.vgn-skip-navigation:focus,
.vgn-skip-navigation:active {
  display: none !important;
}

#app-skipnav {
  position: absolute;
  overflow: hidden;
  top: -999px;
}

#app-skipnav:focus,
.app-skipnav:active {
  background-color: $c11n-color-ink-000;
  color: $c11n-color-ink-800;
  height: auto;
  left: 4px;
  padding: 6px 8px;
  top: 4px;
  width: auto;
  outline: 2px solid $focus-color;
  z-index: 999;
  border: none;
}

#app-main-content {
  outline: none;
}

.text-hidden{
  font-size: 0;
  width: 0;
  height: 0;
  display: inline-block;
  overflow: hidden;
  position: absolute!important;
  border: 0!important;
  padding: 0!important;
  margin: 0!important;
  clip: rect(1px, 1px, 1px, 1px);
}

